
.input{
    font-family: 'Marcellus', serif;
}
.error-text{
    color: red;
}
.containerbox{
    padding: 2vh;
    background: linear-gradient(270deg, rgba(129, 129, 212, 0.30) 0%, rgba(217, 217, 217, 0.00) 100%);
}

@media (max-width:768px) {
    
    .custom-toast{
        width: 80vw;
        margin-left: 5vw;
        bottom: 4vh;
    }
}