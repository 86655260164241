.footer{
    background: linear-gradient(180deg, #8181D4 0%, #000 100%);
}
i{
    font-size: 5vh;
    color: whitesmoke;
    margin-right: 1vh;
}
i:hover{
    cursor: pointer;
}
i.creditlinks{
    font-size: 2vh;
    color: white;
}