.navbar{
  box-shadow: 1px 1px 3px rgb(201, 201, 201);
}

ul li:hover {
  cursor: pointer;
}

.active {
  background: white !important;
  background:linear-gradient(to right, #8181D4, #000) !important;
  color: transparent !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.button{
  background: linear-gradient(95deg, #8181D4 5.01%, #000 97.96%) !important;
}

/* Navbar.css */
.nav-links {
  transition: all 0.3s ease;
}

.hamburger {
  width: 30px;
  height: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.hamburger div {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background:linear-gradient(to right, #8181D4, #000);
  transition: all 0.3s ease-in-out;
}

.hamburger.open div:nth-child(1) {
  transform: rotate(45deg) translate(5px, 6px);
}

.hamburger.open div:nth-child(2) {
  opacity: 0;
}

.hamburger.open div:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -6px);
}

/* Hide the hamburger icon on medium screens */
@media (min-width: 768px) {
  .hamburger {
    display: none;
  }
}


@media (min-width: 768px) {
  .nav-links {
    display: flex !important;
    
  }
}

@media (max-width: 768px) {
  
  .hamburger{
    top:2.5vh
  }
  .nav-links{
    margin-top: 50px;
  }
  
}


