.gradient-text {
  font-weight: bold;
  background: linear-gradient(
    135deg,
    #ffffff,
    rgb(24, 0, 62),
    #8b80ff,
    #000000,
    #1500fc,
    #f900fd
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  color: transparent;
  animation: gradient 2500ms alternate infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.newarrival{
    transition: transform 1.5s;
    cursor: pointer;
}

/* .swiper-button-prev, .swiper-button-next{
  display: none;
  --swiper-theme-color: rgba(255, 255, 255, 0.831)
} */

.swiper-pagination-bullet{
  --swiper-pagination-bullet-inactive-color:#111
}

.background {
  background: linear-gradient(
    rgba(255, 255, 255, 0.9),
    rgba(4, 0, 53, 0.549)
  ),
  url('../../assests/background3.webp');
  background-size: cover;
  animation: backgroundanime 5s infinite alternate;
}

@media (max-width:768px) {
  .background {
    background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(4, 0, 53, 0.549)
    ),
    url('../../assests/background4.webp');
    background-size: cover;
    animation: backgroundanime 5s infinite alternate;
  } 
  .gradient-text {
    font-weight: bold;
    background: linear-gradient(
      135deg,
      #17004d,
      rgb(255, 255, 255),
      #8b80ff,
      rgb(47, 0, 54),
      #1500fc,
      #f900fd
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    color: transparent;
    animation: gradient 2500ms alternate infinite;
  }
}

@keyframes infiniteScale {
  0% {
    transform: scale(1); /* Initial size */
  }
  50% {
    transform: scale(0.8); /* Scaled size */
  }
  100% {
    transform: scale(1); /* Back to initial size */
  }
}

/* Apply the animation to the images */
.medialinks img {
  animation: infiniteScale 2s ease-in-out infinite !important; /* Change the duration and easing as needed */
}