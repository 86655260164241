.women{
    background: linear-gradient(0deg, rgba(129, 129, 212, 0.30) 0%, rgba(217, 217, 217, 0.00) 104.43%);
}
.comingsoon {
    font-weight: bold;
  background: linear-gradient(
    135deg,
    #1500fc,
    #69ce60,
    #1500fc,
    #000000,
    #1500fc,
    #ffffff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  color: transparent;
  animation: gradient 2500ms alternate infinite;
  }

  @keyframes comingsoon {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
